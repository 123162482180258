<template>
    <b-card>
      <b-card-title>Transactions</b-card-title>
    <b-table
      :items="items"
      :fields="fields"
      responsive
      small
      hover
    >
    </b-table>
  </b-card>
</template>
<script>
import { BTabs, BTab, BCardText,BRow,BCol,BTable,BCard,BCardTitle,BTableLite } from 'bootstrap-vue'
import GraphicsAnalitys from './GraphicsAnalitys.vue';

export default {
  components: {
    BCardText,
    BTabs,
    BTab,BRow,BCol,BTable,BCard,BCardTitle,BTableLite,
    GraphicsAnalitys
  },
  data(){
      let currentUser =JSON.parse(localStorage.getItem("dataSlot"));
    return{
        slot:currentUser.slot,
         fields: [{key:'transaction.signatures[0]',label:'Signature'}],
        items:[],

    }
  },
  mounted:async function(){
   await this.getBlockDetails();
  },
  methods:{
        getBlockDetails:function() {
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/block/transactions?block="+vm.slot+"&offset=0&limit=10",config) .then(function (response) {
        vm.items=response.data;
        console.log(vm.items);
    })
    .catch((err) => {
      console.log(err);
    });
    },
    
         numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}

</script>
<style>
</style>